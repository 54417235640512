import { reaction } from "mobx";

export default class Agent {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    if (typeof window !== "undefined") {
      import("../../service/graph").then(
        ({ fetchFromApiServer, fetchFromApiServerRaw }) => {
          this.fetchFromApiServerRaw = fetchFromApiServerRaw;

          reaction(
            () => this._.user.loaded,
            loaded => {
              if (loaded === false) {
                this.reset();
              }
            }
          );

          reaction(
            () => this.threadId,
            threadId => {
              if (this.history.length) {
                this.thread.refresh(threadId);
              }
            }
          );

          reaction(
            () => this._.user.profile.threads,
            async savedThreads => {
              if (Array.isArray(savedThreads)) {
                if (savedThreads.length) {
                  const threadsArray = await Promise.all(
                    savedThreads.map(this.thread.get)
                  );
                  const threads = {};
                  const sessions = { ...this.sessions };
                  let threadDoesNotExistAnymore = true;

                  for (const thread of threadsArray) {
                    threads[thread.id] = thread;
                    sessions[thread.id] ??= [];

                    if (
                      threadDoesNotExistAnymore &&
                      thread.id === this.threadId
                    ) {
                      threadDoesNotExistAnymore = false;
                    }
                  }

                  if (threadDoesNotExistAnymore) {
                    delete sessions[this.threadId];

                    this.set.threadId();
                  }

                  this.set.threads(threads);
                  this.set.sessions(sessions);
                }

                if (this.loaded === false) {
                  this.set.loaded(true);
                }
              }
            }
          );

          reaction(
            () => [
              this._.user.loaded,
              this._.reader.publisher,
              this._.reader.paperID
            ],
            async ([loaded, publisher, paperID]) => {
              this.set.paperHasBeenRead(false);

              if (loaded && publisher && paperID) {
                const results = await fetchFromApiServer({
                  path: `question/availability/${publisher}/${paperID}`
                });

                this.set.paperHasBeenRead(results?.available ?? false);
              }
            }
          );

          reaction(
            () => [this._.user.loaded, this._.model.node],
            async ([loaded, { repo, modelId }]) => {
              try {
                this.set.canSummarizeEntity(false);

                if (loaded && repo?.description === null) {
                  const { available, description } = await fetchFromApiServer({
                    path: `question/model/${modelId}`
                  });

                  if (description) {
                    const node = { ...this._.model.node };

                    node.repo.description = description;

                    this._.model.set.node(node);
                  }
                  // not using, delete?
                  this.set.canSummarizeEntity(available ?? false);
                }
              } catch (error) {
                console.error(error);
              }
            }
          );
        }
      );

      import("../../service/firebase/firestore").then(({ firestore }) => {
        this.firestore = firestore;
      });
    }
  }
  set = {
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
    // if out of credits
    disable: (disable = false) => {
      this.disable = disable;
    },
    threads: (threads = {}) => {
      this.threads = threads;
    },
    threadId: threadId => {
      this.threadId = threadId;
    },
    running: (running = false) => {
      this.running = running;
    },
    sessions: (sessions = {}) => {
      this.sessions = sessions;
    },
    // docs
    paperHasBeenRead: (paperHasBeenRead = false) => {
      this.paperHasBeenRead = paperHasBeenRead;
    },
    canSummarizeEntity: (canSummarizeEntity = false) => {
      this.canSummarizeEntity = canSummarizeEntity;
    },
    // auto agent messages
    greeting: greeting => {
      this.greeting = greeting;

      if (greeting) {
        this.message.add(greeting, false);
      }
    },
    needsToSayHi: (needsToSayHi = true) => {
      this.needsToSayHi = needsToSayHi;
    }
  };
  chat = async userInput => {
    try {
      var input = userInput.trim();
      const { threadId, session } = this;
      const [vector] = await Promise.all([
        this._.search.vectorsSupported
          ? this._.search.vectorWorker.toVector(input)
          : undefined,
        this.message.add({ role: "user", content: input })
      ]);
      const assistantRandomId = Math.random();
      const thread = this.threads[threadId];
      const historyWithoutAssistantMessage = [...thread.history];

      thread.history = [
        ...historyWithoutAssistantMessage,
        {
          id: assistantRandomId,
          created: new Date(),
          role: "assistant",
          text: ""
        }
      ];

      this.set.threads({ ...this.threads, [threadId]: thread });
      this.set.running(true);

      const res = await this.fetchFromApiServerRaw({
        path: "agent",
        body: {
          vector,
          session,
          threadId,
          userInput: input,
          history: this.history
            .slice(-12, -2)
            .map(({ sources, ...message }) => message)
        }
      });

      const readableStream = res.body
        .pipeThrough(new TextDecoderStream())
        .getReader();
      let streamError = false;

      readableStream.closed?.catch(() => {
        streamError = true;
      });

      do {
        try {
          var { done, value } = await readableStream.read();

          if (done === false) {
            var {
              step,
              text,
              created,
              sources = [],
              citations = [],
              id = assistantRandomId
            } = JSON.parse(value.trim().split("\n").pop());

            thread.history = [
              ...historyWithoutAssistantMessage,
              {
                id,
                step,
                citations,
                sources,
                text,
                role: "assistant",
                created: new Date(created)
              }
            ];

            this.set.threads({ ...this.threads, [threadId]: thread });
          }
        } catch (error) {
          if (error.message.includes("JSON") === false) {
            console.error(error);
            console.log("error", { value });
          }
        }
      } while (streamError === false && done === false);
    } catch (error) {
      console.error(error);
    } finally {
      this.set.running(false);
      this._.analytics.track.usage.for("agent");
      this._.analytics.track.event("Agent", { input, output: text });
    }
  };
  thread = {
    create: async userInput => {
      const res = await fetch("/api/agent");
      const thread = await res.json();

      thread.modified = new Date(thread.modified);
      thread.created = new Date(thread.created);

      this.set.threads({ ...this.threads, [thread.id]: thread });
      this.set.threadId(thread.id);

      if (userInput) {
        this.chat(userInput);
      }

      await this._.user.data.update({
        threads: this.firestore.arrayUnion(thread.id)
      });
    },
    get: async threadId => {
      const res = await fetch(`/api/agent?threadId=${threadId}`);
      const thread = await res.json();

      thread.created = new Date(thread.created);
      thread.modified = new Date(thread.modified);

      for (const message of thread.history) {
        message.created = new Date(message.created);
      }

      return thread;
    },
    refresh: async threadId => {
      const { history, modified } = await this.thread.get(threadId);

      this.set.threads({
        ...this.threads,
        [threadId]: { ...this.threads[threadId], history, modified }
      });
    },
    delete: async () => {
      this._.snackbar.notify({ text: "Deleting thread..." });

      await Promise.all([
        fetch(this.agentUrl, {
          method: "DELETE",
          body: JSON.stringify({ threadId: this.threadId })
        }),
        this._.user.data.update({
          threads: this.firestore.arrayRemove(this.threadId)
        })
      ]);

      this._.snackbar.notify({ text: "Thread deleted" });
    }
  };
  message = {
    add: async ({ role, content, metadata }) => {
      try {
        // if unique message, only add once
        if (metadata) {
          const [key, value] = metadata;
          let i = this.history.length;

          while (i--) {
            // do not write the same metadata message twice
            if (this.history[i].metaData?.[key] === value) {
              // console.log("already added");
              return; // already added this message
            }
          }
        }

        const threadId = this.threadId;
        const res = await fetch(this.agentUrl, {
          method: "post",
          body: JSON.stringify({ role, content, metadata })
        });

        if (res.ok) {
          const message = await res.json();
          const thread = this.threads[threadId];

          if (thread) {
            message.created = new Date(message.created);
            thread.history = [...thread.history, message];

            this.set.threads({ ...this.threads, [threadId]: thread });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    delete: async (messageId, notify = true) => {
      try {
        this.set.threads({
          ...this.threads,
          [this.threadId]: {
            ...this.threads[this.threadId],
            history: this.history.filter(message => message.id !== messageId)
          }
        });

        await fetch(this.agentUrl, {
          method: "DELETE",
          body: JSON.stringify({ messageId, threadId: this.threadId })
        });

        if (notify) {
          this._.snackbar.notify({ text: "Deleted" });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  byModified = (a, b) => b.modified - a.modified;
  get agentUrl() {
    return `/api/agent${this.threadId ? `?threadId=${this.threadId}` : ""}`;
  }
  get pages() {
    return new Set(this.session.map(({ page }) => page));
  }
  get maxThreads() {
    return this._.user.premium
      ? false
      : (this._.user.isAnonymous ? 1 : 2) <=
          this._.user.profile.threads?.length;
  }
  get threadsArray() {
    return Object.values(this.threads).sort(this.byModified);
  }
  get currentThread() {
    return this.threads[this.threadId];
  }
  get history() {
    return this.currentThread?.history ?? [];
  }
  get session() {
    return this.sessions[this.threadId] ?? [];
  }
}
