export default class Model {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);
  }
  set = {
    clusterDoc: (clusterDoc = {}) => {
      this.clusterDoc = clusterDoc;
    },
    node: (node = {}) => {
      this.node = node;
    },
    readme: (readme = "") => {
      this.readme = readme;
    },
    json: (json = {}) => {
      this.json = json;
    },
    related: (related = []) => {
      this.related = related;
    },
    input: input => {
      this.input = input;
    },
    output: output => {
      this.output = output;
    },
    error: error => {
      this.error = error;
    },
    running: (running = false) => {
      this.running = running;
    }
  };
}
