import { transaction } from "mobx";

export default class BottomSheet {
  constructor(makeMobxStore) {
    this.reset = makeMobxStore(this);
  }
  // actions
  set = {
    open: (open = false) => {
      this.open = open;
    },
    onClose: (onClose = () => this.reset()) => {
      this.onClose = onClose;
    },
    Component: Component => {
      this.Component = Component;
    },
    state: (state = {}) => {
      this.state = state;
    },
    sx: (sx = {}) => {
      this.sx = sx;
    },
    peek: (peek = false) => {
      this.peek = peek;
    },
    expanded: (expanded = false) => {
      this.expanded = expanded;
    }
  };
  configure = Component => {
    transaction(() => {
      if (this.expanded) {
        // if open, close
        this.set.expanded(false);
        // wait for close, then mount and open next bottom sheet
        setTimeout(() => {
          this.set.Component(Component);
          this.set.expanded(true);
        }, 600);
      } else {
        // if closed, mount and open
        this.set.Component(Component);
        this.set.open(true);
      }
    });
  };
  toggle = () => this.set.open(!this.open);
}
