export default class Device {
  constructor(makeMobxStore) {
    this.reset = makeMobxStore(this);
  }
  set = {
    size: (size = "") => {
      this.size = size;
    },
    scrolling: (scrolling = false) => {
      this.scrolling = scrolling;
    }
  };
  get isPhone() {
    return this.size === "compact" || this.size === "medium";
  }
  get isNotPhone() {
    return this.isPhone === false;
  }
  get isTablet() {
    return this.size === "expanded";
  }
  get isDesktop() {
    return this.size === "large" || this.size === "extraLarge";
  }
  get measured() {
    return this.size !== "";
  }
}
