import { reaction } from "mobx";

export default class Tasks {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    import("../graph").then(({ graphQuery }) => {
      reaction(
        () => this._.user.loaded,
        loaded => {
          if (loaded) {
            graphQuery({ query: this._.gql.get("tasks") }).then(({ data }) =>
              this.set.list(data?.tags)
            );
            graphQuery({ query: this._.gql.get("huggingfaceTasks") }).then(
              ({ data }) => this.set.model(data?.tags)
            );
          }
        }
      );
    });

    //

    this.url = Tasks._url;
  }

  set = {
    list: (list = []) => {
      this.list = list;
    },
    model: (model = []) => {
      this.model = model;
    }
  };

  static _url = {
    encode: task => task?.replace(/ /g, "_"),
    decode: task => task?.replace(/_/g, " "),
    route: task => `/task/${this._url.encode(task)}`
  };
  get map() {
    return new Map(this.list.map(tag => [tag.name, tag]));
  }
  get _set() {
    return new Set(this.list.map(tag => tag.name));
  }
  get _setModel() {
    return new Set(this.model.map(tag => tag.name));
  }
  get array() {
    return [...this._set];
  }
}
