import { reaction, when } from "mobx";

import { firebaseApp } from "../firebase";

export default class Analytics {
  constructor(makeMobxStore, _) {
    this._ = _;
    this.reset = makeMobxStore(this);

    // track number of mins user is actively interacting with app
    if (typeof window !== "undefined") {
      Promise.all([
        import("firebase/analytics"),
        import("mixpanel-browser"),
        import("@amplitude/analytics-browser")
      ]).then(
        ([
          { getAnalytics, logEvent, setUserProperties },
          { default: mixpanel },
          amplitude
        ]) => {
          const isProduction =
            process.env.NODE_ENV === "production" &&
            window.TEST === undefined &&
            window.location.hostname === "bytez.com";

          this.mixpanel = mixpanel;
          this.amplitude = amplitude;

          amplitude.init(
            isProduction
              ? "86dd73bdeb324642099cff55fb70d4be"
              : "4c96a794f7b5d393d337cc4600f99ed8",
            undefined,
            {
              defaultTracking: true,
              // appVersion: packageJSON.version,
              transport: "beacon",
              useBatch: false
            }
          );

          mixpanel.init(
            isProduction
              ? "677d30d236babbeb44c189b84424fd67"
              : "4367a57e7b30b980eee72802d1f825d1",
            {
              api_transport: "sendBeacon",
              batch_requests: false,
              persistence: "localStorage",
              ignore_dnt: true
              // debug: process.env.NODE_ENV === "development"
            }
          );

          const firebaseAnalytics =
            typeof window === "undefined"
              ? () => {}
              : getAnalytics(firebaseApp);

          this.firebaseAnalytics = {
            track:
              process.env.NODE_ENV === "development"
                ? () => {}
                : (eventName, props) =>
                    logEvent(firebaseAnalytics, eventName, props),
            setUserProfile:
              process.env.NODE_ENV === "development"
                ? () => {}
                : profile => setUserProperties(firebaseAnalytics, profile)
          };

          this.set.loaded(true);
        }
      );

      reaction(
        () =>
          process.env.NODE_ENV === "production" &&
          this.loaded &&
          this._.user.loaded &&
          this._.layout.checkedWhiteLabel &&
          this._.reader.whiteLabelled === false &&
          this._.user.profile.cohort !== undefined,
        async shouldRecordSession => {
          if (shouldRecordSession) {
            const { user } = this._;
            const wid = 648526056;

            window.__insp ??= [["wid", wid]];
            window.__insp.push([
              "tagSession",
              {
                uid: user.uid,
                email: user.session.email,
                cohort: user.profile.cohort,
                firstExperience: user.firstExperience
              }
            ]);

            if (document.getElementById("inspsync") === null) {
              const script = document.createElement("script");

              script.async = true;
              script.id = "inspsync";
              script.src = `//cdn.inspectlet.com/inspectlet.js?wid=${wid}&r=${Math.floor(
                Date.now() / 36e5
              )}`;

              document.head.appendChild(script);
            }

            const { sessionReplayPlugin } = await import(
              "@amplitude/plugin-session-replay-browser"
            );

            this.amplitude.add(
              sessionReplayPlugin({ forceSessionTracking: true })
            );
          }
        }
      );
      reaction(
        () => this.loaded && this._.user.loaded,
        userLoaded => {
          if (userLoaded) {
            this.reset();
            this.track.engagement();
            this.track.event("App Open");
          }
        }
      );
      // track user at session start
      reaction(
        () => this.loaded && this._.user.isAnonymous === false,
        isNotAnonymous => {
          if (isNotAnonymous) {
            this.track.user.id();
            this.track.user.profile();
          }
        }
      );
    }
  }
  set = {
    loaded: (loaded = false) => {
      this.loaded = loaded;
    },
    meters: (meters = {}) => {
      this.meters = meters;
    },
    context: (container, action, props = {}) => {
      let context = {};

      if (container && action) {
        const eventName = this.capitalize(`${container} ${action}`);
        // track the initial event
        this.track.event(eventName, props);
        // set the event name to container, so it can be passed to children
        context = { ...props, container: eventName };
      }

      this.context = context;
    }
  };
  track = {
    event:
      typeof window === "undefined"
        ? () => {}
        : async (eventName, properties) => {
            await this.initAnalytics();

            const props = {
              ...this.enrichedContext,
              ...properties,
              time: new Date()
            };

            eventName = this.capitalize(eventName);

            this.firebaseAnalytics.track(eventName, props);
            this.mixpanel.track(eventName, props);
            this.amplitude.track(eventName, props);

            console.debug("event", { name: eventName, props });
          },
    engagement: () => {
      const eventsToMonitor = ["click", "keypress", "wheel", "touchstart"];
      let engaged = true;
      // if user engages, record, and remove listeners
      function checkIfUserEngaged() {
        if (engaged === false && document.hidden === false) {
          let i = eventsToMonitor.length;

          while (i--) {
            window.removeEventListener(eventsToMonitor[i], checkIfUserEngaged);
          }

          engaged = true;
        }
      }
      clearInterval(this.engagementTimer);
      // record engagement 1 min
      this.engagementTimer = setInterval(() => {
        if (engaged) {
          let i = eventsToMonitor.length;
          // lets monitor these events, to see if user is using app
          while (i--) {
            window.addEventListener(eventsToMonitor[i], checkIfUserEngaged, {
              passive: true
            });
          }
          // reset
          engaged = false;
          // track
          this.track.event("User Engaged");
        }
      }, 60e3);
    },
    user: {
      id: async () => {
        if (this._.user.isAnonymous === false) {
          await this.initAnalytics();

          this.mixpanel.identify(this._.user.uid);
          this.amplitude.setUserId(this._.user.uid);
        }
      },
      profile: async () => {
        if (this._.user.isAnonymous === false) {
          await this.initAnalytics();

          const { user } = this._;
          const analyticsProfile = {
            $avatar: user.session.photoURL,
            $distinct_id: user.uid,
            $email: user.session.email,
            $name: user.session.displayName,
            ...user.profile
          };

          // if (org.exists) {
          //   analyticsProfile.orgId = org.id;
          //   analyticsProfile.orgName = org.name;
          // }

          this.firebaseAnalytics.setUserProfile(analyticsProfile);
          this.mixpanel.people.set(analyticsProfile);

          const profile = new this.amplitude.Identify();

          for (const key in analyticsProfile) {
            profile.set(key, analyticsProfile[key]);
          }

          this.amplitude.identify(profile);
        }
      }
    },
    usage: {
      get: () => this._.utilities.stripe("GET"),
      for: (feature, value = 1) => {
        const meters = { ...this.meters };

        meters[feature] ??= 0;
        meters[feature] += value;

        this.set.meters(meters);
        this._.utilities.stripe(
          "PATCH",
          JSON.stringify({ feature, usage: value })
        );
      }
    }
  };
  capitalize = eventName =>
    eventName
      .split(" ")
      .map(subname => `${subname[0].toUpperCase()}${subname.slice(1)}`)
      .join(" ");

  signOut = async () => {
    await this.initAnalytics();

    this.track.event("User Logout");
    this.mixpanel.reset();
    this.amplitude.reset();
  };
  initAnalytics = async () => {
    if (this.loaded === false) {
      await when(() => this.loaded);
    }
  };
  get enrichedContext() {
    return {
      // ...properties,
      context: this.context,
      // mixpanel specific attributes
      // user
      userId: this._.user.uid,
      userName: this._.user.session.displayName,
      cohort: this._.user.profile.cohort,
      //
      // userAccountType: accountType, TODO: what is this?
      //
      // org
      // orgId: this._.org.id,
      // orgName: this._.org.name,
      // surface specific attributes
      surface: "pwa",
      // appVersion: packageJSON.version,
      // duplicate info for mixpanel, but useful to us for BE call
      pathname:
        typeof window === "undefined" ? undefined : window?.location.pathname,
      screenSize: this._.device.size
    };
  }
}
